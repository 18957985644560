import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Privacy from './Privacy';

import './App.css';

export default function App() {

  return (
    <Container className="d-flex pt-5">
      <Row>
        <Col xs={1} sm={0} md={2} ></Col>
        <Col xs={10} sm={12} md={8} className='d-flex justify-content-center align-items-center flex-column'>
          <div>
            <img src="/img/logo.png" alt="Geekbox Computers, LLC." />
          </div>
          <h3>Welcome!</h3>
          <div className='mb-5'>
            <a style={{textDecoration: 'none' }} href="mailto:cr@geekbox.com" target="_blank" rel="noreferrer">Contact support</a>
          </div>
          <Privacy />
        </Col>
        <Col xs={1} sm={0} md={2} ></Col>
      </Row>
    </Container>
  )
}