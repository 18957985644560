import React from 'react';

export default function Privacy() {
    return (
        <>
            <h3>GeekBox Computers, LLC</h3>
            <h4>Privacy Policy</h4>
            <p>This privacy policy sets out how GeekBox Computers, LLC and its family of companies (hereinafter collectively &ldquo;SEB&rdquo;) uses and protects any information that you give SEB (&ldquo;Customer Information&rdquo;), when you use its products and services. SEB is a computer hardware and software retailer, offering a diverse selection of services including, technical support, industry leading computer hardware sales of every kind, backup solutions, mobile computing solutions, networking solutions and diagnostics, digital entertainment solutions, and the like.</p>
            <p>SEB is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using SEB&rsquo;s products or services you can be assured that such information will only be used in accordance with this privacy statement. SEB may change this policy from time to time without notice. You should periodically check with SEB from time to time to ensure that you are happy with any changes. This policy is effective from January 1, 2010.<br />
                <br />
                SEB collects Customer Information in a variety of settings. For example, when a party purchases a computer SEB collects a variety of Customer Information for sales, service and warranty purposes; when a Customer originates a call for technical support, logs onto a SEB website, brings a computer for repair or warranty service, SEB, depending on the circumstances, might collect the following information:</p>
            <ul>
                <li>Name.</li>
                <li>Contact information including email address.</li>
                <li>Computer hardware or software information.</li>
                <li>IP address, and ISP.</li>
                <li>Other information relevant to customer needs.</li>
            </ul>
            <p>Depending on the circumstances, SEB collects various information to understand your needs and provide you with extraordinary service, and in particular for the following reasons:</p>
            <ul>
                <li>Internal record keeping, accounting, auditing, and customer service evaluation.</li>
                <li>Warranty tracking.</li>
                <li>Improving SEB&rsquo;s products and services.</li>
                <li>Sending promotional materials about new or improved SEB products, or services and other information which we think you may find interesting using the email address which you have provided.&nbsp;</li>
                <li>We will never sell or lease your information.</li>
            </ul>
            <p><strong>Security</strong>&nbsp;<br />
                We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
            <p><strong>How we use cookies</strong>&nbsp;<br />
                A cookie is a small file which asks permission to be placed on your computer&#39;s hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.&nbsp;<br />
                <br />
                We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.&nbsp;<br />
                <br />
                Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.&nbsp;<br />
                <br />
                You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.<br />
                <br />
                <strong>Links to other websites</strong><br />
                Our website may contain links to enable you to visit other websites of interest easily. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.<br />
                <br />
                <strong>Controlling your personal information</strong><br />
                You may choose to restrict the collection or use of your personal information in the following ways:</p>

            <ul>
                <li>whenever you are asked for personal information you may inform us that you want your information used only in certain ways; and,</li>
                <li>if you have previously agreed for us to use your personal information for any purpose, you may change your mind at any time by notifying SEB in writing at the following address: 10288 S. Jordan Gateway; Suite D; South Jordan, UT 84095.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
            </ul>
            <p>We will not sell or lease your personal information to third parties unless we have your permission or are required by law. We may use your personal information to send you promotional information about SEB products and services.</p>
            <p>GeekBox Computers does not knowingly collect personal information from children under the age of 13. If we learn that we have collected personal information on a child under the age of 13, we will delete that data from our systems. GeekBox Computers encourages parents and guardians to go online with their children. Here are a few tips to help make a child&#39;s online experience safer:</p>
            <ul>
                <li>Teach children never to give personal information (such as name, address, phone number, school, etc.) unless supervised by a parent or responsible adult.</li>
                <li>Know the sites your children are visiting and which sites are appropriate.</li>
                <li>Look for website privacy policies. Know how your child&#39;s information is treated.</li>
            </ul>
            <p>If you believe that any Customer Information is incorrect or incomplete, please write to or email us as soon as possible. SEB will promptly correct any information found to be incorrect.</p>

        </>
    )
}